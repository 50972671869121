import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element", "clearInput"];

  toggle(event) {
    this.elementTargets.forEach((el) => {
      el.classList.add("hidden");

      this.clearInputTargets.forEach((e) => {
        if (
          event.target.name == "notify_me_options" &&
          e.className.split(" ").includes("notify_me")
        ) {
          e.value = null;
        }
        if (
          e.value != event.target.value &&
          !e.className.split(" ").includes("notify_me")
        ) {
          e.value = null;
        }
      });
    });

    if (event.target.checked) {
      this.elementTargets.forEach((el, i) => {
        if (el.getAttribute("data-key") == event.target.value) {
          el.classList.remove("hidden");
          el.focus();
        }
      });
    }
  }
}
